@import "base.css";
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Ligconsolata";
  src: url('./font/ligconsolata-regular.ttf');
}

@font-face {
  font-family: "LigconsolataBold";
  src: url('./font/ligconsolata-bold.ttf');
}

body {
  background-color: theme('colors.green2');
  color: theme('colors.white');
  font-family: 'Ligconsolata';
}

#fontLigBold {
  font-family: 'LigconsolataBold';
}